/* Business board interface */
@import "light";

/* Variables */
@import "variables";

/* Import elements */
@import "forms";

/* Import layout elements */
@import "content";

/* Import components */
@import "board";
@import "map";
@import "search";
@import "recommendations";

/* Entities */
@import "company";
@import "flux";
@import "synergy";
@import "business";


html:lang(fr) .custom-file-label[for="id_step4-logo"]::after {
  content: "Ajouter votre logo"
}

html:lang(it) .custom-file-label[for="id_step4-logo"]::after {
  content: "Carica un'immagine"
}

html:lang(fr) .custom-file-label[for="id_picture"]::after {
  content: "Ajouter une image"
}

html:lang(it) .custom-file-label[for="id_picture"]::after {
  content: "Carica un'immagine"
}

.flex-grow {
  flex: 1;
}

.actif-height {
  height: calc(100% - 110px) !important;
}

#div_id_search .bootstrap-autocomplete.dropdown-menu {
  right: 0;
  left: auto !important;
}