/* Business styles *
 * -------------- */

$color-background : #E2141D;
$color-button-ad : #ee2737;
$color-button-pdf : #363742;

.footer{
    
    &-home {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    &-partners {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 20%;
    }
}

// interrogatio point after input in create/update flux form
.interrogation-point{

    &:after {
        content: " \f059";
        /* content: " \f29c"; */
        font-family: 'Font Awesome 5 Free';
        vertical-align: middle;
    }

    &:after:hover {
        content: '' attr(title);
        position: absolute;
        top: -22px;
        left: 10px;
        background: black;
        color: white;
        padding: 2px;
        border: 1px solid #eee;
        opacity: 1;
        transition: 0.5s opacity;
    }
}

:root {
    --ribbon-color: #00487c;
    --ribbon-color-shadow: #001c30;
}


.modal {
    overflow-y:auto;
}

.circle {
    background: #e3e3e3;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    color: #6e6e6e;
    display: inline-block;
    font-weight: bold;
    line-height: 1.7rem;
    margin-right: 0.25rem;
    text-align: center;
    width: 1.7rem;
}

.selected {
    background: #94cc74 !important;
    color: #ffffff !important;
}

.option {
    border-radius: 10px;
    background: #e3e3e3;
    color: #363742;
    display: inline-block;
    font-weight: bold;
    line-height: 2rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    text-align: center;
}

.title-behind-lined {
  overflow: hidden;
  text-align: center;
}

.title-behind-lined:before,
.title-behind-lined:after {
  background-color: #000000;
  content: "";
  background: #cccccc;
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align:middle;
  width: 50%;
}

.title-behind-lined{
    &:before {
         right: 0.5em;
         margin-left: -50%;
    }

    &:after {
        left: 0.5em;
        margin-right: -50%;
    }
}

.border-ad {
    justify-content: stretch;
    min-width: 300mm;
    max-width: 300mm;
    
    &-fancy {
        min-height: 430mm;
        max-height: 430mm;
        margin-inline: 0em;
        padding: 4rem;
        padding-top: 2rem;
    }
}

.img {
    &-flux {
        // aspect-ratio: 16/9;
        object-fit:contain;
        top:0;
        display:flex;
        height: 100%;
        max-height: 150mm;
        width: 100%;
    }
    
    &-box {
        margin-bottom: -1rem;
        width: 300mm;
        min-height: 150mm;
        max-height: 155mm;
        left:-4rem;
        height: 100%;
        border-left: 30mm solid #ffffff;
        border-right: 30mm solid #ffffff;
        border-top: 1mm solid #ffffff;
        border-bottom: 1mm solid #ffffff;
        display: flex !important;
        justify-content: center;
        align-content: center;
        top: 22px;
    }
}

body.text-center-web {
    // text-align: -webkit-center;
    // position: relative;
    display: flex;
    justify-content: center;
}

.background-shadow {
    border-radius: 1rem;
    min-width: 264mm;
    max-width: 264mm;
    min-height: 165mm;
    max-height: 165mm;
    width: 264mm;
    height: 100mm;
}

.ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
  
  &:before,
  &:after {
    position: absolute;
    z-index: -1;
    content: '';
    display: flex;
    border: 5px solid var(--ribbon-color-shadow);
  }
  span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background-color: var(--ribbon-color);
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font: 700 18px/1 'Lato', sans-serif;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
  }
  &-top-left {
    top: -10px;
    left: -10px;

    &:before,
    &:after {
      border-top-color: transparent;
      border-left-color: transparent;
    }
    &::before {
      top: 0;
      right: 0;
    }
    &::after {
      bottom: 0;
      left: 0;
    }
    & span {
      z-index: 100;
      right: -25px;
      top: 30px;
      transform: rotate(-45deg);
    }
  }
}

.public-ad {
    display: flex;
    flex-direction: column;
    height: 180mm;
    &-part {
        justify-content: space-evenly;
        &-bottom {
            height: 1000px;
            position: relative;

        }
    }
}

.actif-intro {
    margin-bottom: 3rem;
    // margin-left: -2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    align-content: center;

    &-img {
        // padding-right: 1rem;
        transform: scale(1.5);
    }

    &-tags {
        // padding-right: 1rem;
        font-size: xx-large;

        &-empty {
          margin-top: 13mm;
        }
    }

    &-title {
        font-weight: bold;
        font-size: x-large;
    }
}

.product-name {
    text-align: start;
}

.btn-ad {    
    &-contact {
        background: $color-button-ad;
        border-color: $color-button-ad;
    }
    
    &-pdf {
        background: $color-button-pdf;
        border-color: $color-button-pdf;
    }

    &-contact:hover,
    &-pdf:hover 
    {
        color: #fff !important;
        opacity: 1;
        transition: 0.3s;
        font-size: large;
    }

    &-contact,
    &-pdf 
    {
        height: 18mm;
        width: 100mm;
        // z-index: 1;
        color: #fff;
        flex: 1;
        padding: 1rem;
        border:solid;
        transition: 0.2s;
        border-radius: 1rem;
        opacity: 0.9;
        span {
          z-index: 50;
          font-size: x-large;
          font-family: "Fira Sans","Roboto","Helvetica Neue","Arial","Noto Sans","sans-serif";
          font-weight:700;
        }
    }
}

section.printable {
    width: 100%;
    height: 424mm;
    page-break-after: auto;
    background-color: #ffffff;
    margin:0;
    padding:0;
    top: 1cm;
    justify-self: stretch;
}

body.text-center-web {
    background: $color-background ;
  }

.bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bubbles li {
  position: absolute;
  list-style: none;
  display: block;
  width: 20px;
  border-radius: 100%;
  height: 20px;
  background: rgba(255, 255, 255, .2);
  animation: animate 15s linear infinite;
  bottom: -200px;
}

.bubbles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0;
}

.bubbles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.bubbles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.bubbles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 12s;
}

.bubbles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.bubbles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.bubbles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.bubbles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.bubbles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.bubbles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;    
  }
  100% {
    -webkit-transform: translateY(-200vh);
    -ms-transform: translateY(-200vh);
    -o-transform: translateY(-200vh);
    transform: translateY(-200vh);
    opacity: 0;
  }
}

.partners {
    display: block;
    text-align: -webkit-center;
    text-align:    -moz-center;
    text-align:         center;
    margin-top: 45rem;
    padding-top: 1rem;
    padding-bottom: 5rem;
    width: 100%;
    height: 415mm;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: stretch;
}

.hole {
    position: absolute;
    display: block;
    left: 0mm;
    top: 16.1rem;
    width: 100%;
    height: 148mm;
    background-color: $color-background;
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, .0);
}

@media print {
    section {
        overflow:hidden;
    }
}

.unique-ad-btn-click:hover {
    background-color: #dee2e6;
}
