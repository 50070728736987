/* Colors
 * */

$gray-4: #454545;
$gray-5: #5b5b5b;
$gray-9: #999;
$gray-c: #CCC;
$gray-e: #E5E5E5;
$white: #F6F7F1;

$blue-dark: #0B305A;
$blue-medium: #20558A;

$blue-roi: #4971EF;
$orange-peach: #D27251;

$brand-primary: $blue-medium;
$brand-success: #55ab55;
$brand-danger: #d9534f;
$state-danger-bg: #f2dede;

$input-bg-color: $blue-roi;
$output-bg-color: $orange-peach;

$padding-base-vertical: 10px;
$padding-base-horizontal: 15px;
$padding-base: $padding-base-vertical $padding-base-horizontal;

$custom-file-text: (
  en: "Browse",
  fr: "Selectionner"
);
