/* Forms
** */

.validation-error-message {
  display: table-footer-group;
}

.has-error {
  background-color: $state-danger-bg;
  margin-left: 0;
  margin-right: 0;
  padding: 10px 0 0;
  .help-block {
    clear: both;
  }
}

.required label::after {
  content: " *";
  color: $brand-danger;
}

.requiredField .asteriskField {
  color: $brand-danger;
}

.radio, .checkbox {
	display: inline-block;
	padding-right: 20px;
}

#id_city, #id_unit {
  & + .select2-container {
    min-width: 10em;
    max-width: 100%;
  }
}

// make select2 working with bootstrap
.select2-selection.form-control {
  height: unset;
  padding: unset;
}
