/* Board styles *
 * ------------ */

.board {
  height: 100%;
  padding: 0;

  .nav-tabs.nav-justified > li {
    display: table-cell;
    width: 1%;
  }

  /* Board header */
  &-header {
    background-color: $gray-4;
    color: white;
    line-height: 40px;
    .title {
      background-color: $gray-4;
      font-size: 18px;
      text-transform: uppercase;
    }
    .controls {
      background-color: $gray-5;
      text-align: center;
    }
  }

  /* Board list results */
  &-list {
    background-color: white;
    &-toolbox {
      padding-bottom: $padding-base-vertical;
    }
  }

  /* Board company identity */
  &-identity {
    &-toolbox {
      margin-bottom: 10px;
      text-align: right;
    }
  }

  /* Board search */
  &-search {
    background-color: $gray-e;

    .nav {
      min-height: 42px;
    }
  }
  &-recommendations {
    height: 100%;
    background-color: $gray-e;
  }

  /* Tabs in board */
  .nav-tabs {
    > li.active {
      > a {
        background-color: $white;
        @media (min-width: 780px) {
          border-bottom-color: $white;
        }
      }
    }
  }

  .scroll-pane {
    overflow-y: auto;
    overflow-x: hidden;
    flex: content-box;
  }
}
