#recommendations{
  &-form {
    padding: 10px 15px;

    .flux-form {
      padding: 0;
    }
  }
  &-form-collapse {
    max-height: 50vh;
    padding: $padding-base;
  }
}

.board-recommendations {
  .synergy-recommendation {
    border: 1px solid $gray-e;
    margin-bottom: $padding-base-vertical;
    .synergy-flux {
      position: relative;
    }
    .flux {
      &-company {
        font-weight: bold;
      }
      &-quantity, &-value {
        font-size: 0.85em;
        font-style: italic;
      }
      &-out, &-in {
        .fa {
          position: absolute;
          top: 0.9em;
        }
      }
      &-out {
        padding-right: 30px;
        .fa {
          color: $output-bg-color;
          right: 10px;
        }
      }
      &-in {
        padding-left: 30px;
        .fa {
          color: $input-bg-color;
          left: 10px;
        }
      }
    }
    &-actions {
      margin-bottom: $padding-base-vertical;
    }
  }
  #recommendations {
    &-substitution {
      height: calc(100% - 160px);
    }
    &-mutualization {
      height: calc(100% - 160px);
      .synergy-recommendation {
        padding: $padding-base;
        background-color: white;
        .synergy-subclass h3 {
          float: left;
          width: 50%;
          font-size: 1.5em;
          margin-top: 0;
        }
        .out .fa {
          color: $output-bg-color;
        }
        .in .fa {
          color: $input-bg-color;
        }
        .table td {
          padding: 4px 0;
        }
        .synergy-total-quantity {
          font-size: 1.2em;
        }
      }
    }
  }

}
