/* Content styles *
 * -------------- */

/* Layout */
html, body {
  height: 100%;
}
main >.row {
  height: 100%;
}
#content,
#map {
  height: 100%;
}
#content {
  @media (min-width: 992px) {
    overflow: hidden;
  }
}

/* Hijacking */
.hijacked-warning-bootstrap {
  position: relative;
}

/* Scalfolding */

.subtitle {
  background-color: $blue-medium;
  color: white;
  padding: $padding-base;
}
span.subtitle {
  width: 100%;
  display: block;
  margin: 0 0 10px;
  min-width: 30%;
}

.message-block {
  padding: $padding-base;
  margin-bottom: $padding-base-horizontal;
}

/* Table */
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  border-top: 0;
  border-bottom: 1px solid $gray-c;
}

.loader-progress {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
  text-align: center;
  z-index: 1000;
  img {
    margin-top: 200px;
    vertical-align: middle;
  }
}

[data-toggle="collapse"] .icon-toggle-chevron:before {
  content: "\f139";
}

[data-toggle="collapse"].collapsed .icon-toggle-chevron:before {
  content: "\f13a";
}

#select2-id_classification-results {
  max-height: 550px;
}

.bootstrap-autocomplete {
  /* override bootstrap autocomplete to allow auto width on search fields */
  width: auto !important;
}

/* Keep notifications always on top */
[data-notify] {
  z-index: 9999 !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  color: black;
}

.select2-container {
  width: 100% !important;
  min-width: 100% !important;
}
