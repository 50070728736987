/* Flux styles *
 * ----------- */

.board {
  /* Board list results */
  &-list {
    .flux {
      padding-bottom: 5px;

      &-IN {
        margin-left: 50%;
        .flux-company {
          background-color: $input-bg-color;
        }
      }
      &-OUT {
        margin-right: 50%;
        .flux-company {
          background-color: $output-bg-color;
        }
      }
      &-header {
        text-align: center;
        padding: 0;
      }
      &-classification {
        text-transform: uppercase;
        font-weight: bold;
      }
      &-box {
        box-shadow: 1px 1px 2px $gray-4;
        background-color: white;
      }
      &-company {
        padding: $padding-base;
        color: white;
      }
      &-details {
        padding: $padding-base;
      }
      &-resume {
        font-weight: bold;
      }
      &-city {
        text-align: right;
      }
    }
  }
  &-identity {
    .flux {
      &-in, &-out {
        margin-bottom: 10px;
        margin-top: 10px;
      }
      &-in .subtitle {
        background-color: $input-bg-color;
      }
      &-out .subtitle {
        background-color: $output-bg-color;
      }
      &-content {
        padding: $padding-base;
        background-color: white;
        > span {
          background-color: $gray-e;
          padding: $padding-base;
          margin-right: 10px;
        }
      }
      &-form {
        .submit {
          float: right;
        }
      }
    }
  }

.flux {

  &-form {
    background-color: white;
    padding: $padding-base;
    border: 1px solid $gray-e;
    margin-top: 10px;
    margin-bottom: 10px;
    .submit {
      float: right;
    }
    overflow: auto;
  }
  &-periodicity {
    color: $gray-9;
    font-style: italic;
    font-family: monospace;
    font-size: 0.8em;
    line-height: 1.6;
    span {
      border: 1px solid;
      padding-left: 2px;
      padding-right: 2px;
      background-color: $gray-e;
    }
    .selected {
      color: $brand-primary;
      font-style: normal;
      font-weight: bold;
      background-color: white;
    }
  }
  &-comments {
    margin: 10px 0 0 0;
  }
}
  #id_periodicity .checkbox {
    float: left;
    @media (max-width: 479px) {
      width: 48%;
      &:nth-child(2n+1){
        clear: left;
      }
    }
    @media (min-width: 480px) {
      width: 30%;
      &:nth-child(3n+1){
        clear: left;
      }
    }
  }
}

input[type=date]::-webkit-inner-spin-button, input[type=date]::-webkit-calendar-picker-indicator {
  visibility: hidden;
}
