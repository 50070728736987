/* Synergy styles *
 * -------------- */

/* Commons list and details */
.synergy {
  margin-bottom: 10px;
  border: 1px solid $gray-e;
  &-company {
    display: block;
    font-weight: bold;
  }
  &-classification,
  &-type, &-quantity {
    font-size: 0.85em;
  }
  &-classification {
    font-style: italic;
  }
  &-quantity {
    margin-top: 10px;
  }
  &-content {
    margin-top: 10px;
    padding: $padding-base;
    background-color: white;
  }
  &-flux {
    background-color: white;
    padding: $padding-base;
    margin: $padding-base-vertical 5px;
    box-shadow: 1px 1px 2px $gray-4;
    .flux-company {
      display: block;
    }
  }
  &-more a {
    padding-top: 5px;
    padding-bottom: 5px;
    display: block;
    text-align: center;
    background-color: white;
    &.collapsed .fa-chevron-up::before {
      content: "\f078";
    }
  }

  &-actions {
    text-align: right;
  }
  &-content.edit {
    background-color: white;
    padding: $padding-base;
    border: 1px solid $gray-e;
  }
  &-form {
    background-color: white;
    padding: $padding-base;
    border: 1px solid $gray-e;
  }
}



.board {

  /* Board list results */
  &-list {
    .synergy {
    }
  }

}
