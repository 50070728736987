/* Search styles *
 * ------------- */

#search-form, #search-results {
  min-height: 62px;
  padding: $padding-base;
  .flux-form {
    padding: 0;
  }
  .localize {
    float: right;
  }
}

#search-form-collapse {
  max-height: 50vh;
  #filters_advanced {
    padding: $padding-base;
    border: 1px solid $gray-e;
    margin-bottom: 20px;
  }
}

.hide-form {
  text-align: center;
  padding: $padding-base;
  background: white;
}

#search-results {
  .subtitle h3 {
    font-size: 1.15em;
    margin: 0;
  }
  .flux-quantity, .flux-real-quantity {
    text-align: right;
  }
}
