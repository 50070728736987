/* Company styles *
 * -------------- */

.board {

  /* Board header */
  .company {
    &-title {
      font-weight: bold;
      text-transform: uppercase;
      background-color: $blue-dark;
      color: white;
      font-size: 1.5em;
      min-height: 40px;
    }
    &-close {
      color: white;
      opacity: .7;
      margin-top: 10px;
      &:hover {
        opacity: 1;
      }
    }
  }


  /* Board list results */
  &-list {

    #companies .board-list-toolbox {
      .geocompany-filter, .geoentities-switch {
        float: left;
        padding-right: 15px;
      }
      .tool-left {
        float: left;
      }
      .pagination-display {
        float: right;
        padding-left: 15px;
      }
      .pagination {
        margin: 0;
        // .pagination-counter {

        // }
      }
      .geocompany-filter {
        .form-control {
          display: inline-block;
          width: auto;
          max-width: 100px;
        }
        span .fa {
          margin-left: 5px;
          font-size: 12pt;
          cursor: pointer;
        }
      }
    }
    .company {
      padding: $padding-base;
      background-color: white;
      margin-bottom: 3px;
      display: table;
      width: 100%;
      & > div {
        display: table-row;
      }
      &-logo,
      &-content {
        display: table-cell;
        vertical-align: top;
      }
      &-logo {
        width: 120px;
        padding-right: 15px;
        img:hover {
          cursor: pointer;
        }
      }
      &-content {
        > span {
          display: block;
        }
      }
      &-name {
        text-transform: uppercase;
        display: block;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
        & a {
          text-decoration: none;
          color: black;
        }
      }
    }
  }

  /* Board company identity */
  &-identity {
    .company {
      &-logo {
        float: right;
        width: 120px;
      }
      &-information,
      &-coordonnees {
        padding-bottom: 15px;
        th {
          width: 20%;
        }
      }
      &-actions {
        text-align: right;
      }
    }
  }
}

.company {
  &-form {
    background-color: white;
    padding: $padding-base;
    border: 1px solid $gray-e;
    margin-top: 10px;
    margin-bottom: 10px;
    #form-map {
      margin-bottom: 15px;
    }

    img {
      max-width: 150px;
    }
  }
}

