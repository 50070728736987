$cci-color: #00487c;
$actif-color: #ee2737;
$white: #fff;

$theme-colors: (
"info": $cci-color,
"primary": $cci-color,
"cci": $cci-color,
"danger": $actif-color,
"light": $white,
"cyan": $cci-color,
"white": $white,
);

$card-bg: $white;
$input-bg: $white;
$custom-control-indicator-active-bg: $cci-color;
$custom-control-indicator-focus-border-color: $cci-color;

  
// Override AdminLTE theme
.sidebar {
    .active {
        background-color: rgba(255,255,255,.9) !important;
        color: #343a40 !important;
      }
}

@import "node_modules/bootstrap/scss/bootstrap";

// Override some LTE rules that does not respond well with bootstrap theme overriding
.card-info.card-outline {
    border-top: 3px solid theme-color("info");
}

.callout.callout-info {
    border-left-color: theme-color("info");
}

.card-info:not(.card-outline) > .card-header {
    background-color: $cci-color;
}
