/* Map styles *
 * ---------- */

#map {
  z-index: 1;
}

.map-company {
  .company {
    &-tooltip {

    }
    &-logo,
    &-content {
      display: table-cell;
      vertical-align: top;
    }

    &-logo {
      width: 96px;
      padding-right: 10px;
      img {
        max-width: 100% !important;
      }
    }
    &-name {
      color: $blue-medium;
      font-size: 1.2em;
      text-transform: uppercase;
    }
  }
}

#form-map {
  height: 290px;
}

.legend {
  line-height: 18px;
  color: #555;

  &-text {
    opacity: 0.6;
  }
}

.info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255,255,255,0.8);
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  border-radius: 5px;
}
.info h4 {
  margin: 0 0 5px;
  color: #777;
}
